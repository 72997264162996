<template>
  <div class="container">
    <div class="container row">
      <h1 class="semibold-font">{{ $t('contact_data') }}</h1>
    </div>
    <div class="container row">
      <div class="regular-font company-address">
        <strong>{{ companyName }}</strong><br>
        {{ companyStreet }}
      </div>
    </div>
    <div class="container row">
      <div class="regular-font company-address">
        {{ $t('email') }}: <span class="purple-color"><a :href="'mailto:' + companyEmail">{{ companyEmail }}</a></span><br>
        {{ $t('helpline') }}: <span class="purple-color"><a :href="'tel:' + companyHelpline">{{ companyHelpline }}</a></span><br>
        {{ $t('www') }}: <span class="purple-color">
          <a class="see-more" v-bind:href="'https://' + companyWWW" target="_blank">
            {{ companyWWW }}
          </a>
        </span>
      </div>
    </div>
    <div class="container row">
      <div class="regular-font company-address">
        {{ capitalGroupInfo }}
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Contact',
  computed: {
    capitalGroupInfo () {
      if (location.host.includes('.pl')) {
        return 'Spółka należy do Grupy Kapitałowej Euro-Tax.pl S.A.'
      } else {
        return ''
      }
    },
    companyName () {
      if (this.$i18n.locale === 'ro' || location.host.includes('.ro')) {
        return 'EURO-TAX.RO SERVICII FISCALE EMIGRANTI S.R.L.'
      } else if (this.$i18n.locale === 'bg' || location.host.includes('.bg')) {
        return 'Euro-tax.bg'
      } else if (location.host.includes('.euro-tax.pl')) {
        return 'Euro-Tax.pl Zwrot Podatku S.A.'
      } else {
        return 'CUF sp. z o.o.'
      }
    },
    companyStreet () {
      if (this.$i18n.locale === 'ro' || location.host.includes('.ro')) {
        return 'Str. Drumul Gării Odăi, Nr. 1A, Et.2 Clădirea Airport Plaza intrarea A, Otopeni, Jud. Ilfov Cod poștal: 719182'
      } else if (this.$i18n.locale === 'bg' || location.host.includes('.bg')) {
        return '1797 София, ж.к. Младост 1, бул. „Климент Охридски“ 1А – етаж 3'
      } else if (location.host.includes('.euro-tax.pl')) {
        return 'ul. Sikorskiego 26, 53-659 Wrocław'
      } else {
        return 'ul. Szelągowska 25/2-3, 61-626 Poznań'
      }
    },
    companyEmail () {
      if (this.$i18n.locale === 'ro' || location.host.includes('.ro')) {
        return 'suportclienti@euro-tax.ro'
      } else if (this.$i18n.locale === 'bg' || location.host.includes('.bg')) {
        return 'dok@euro-tax.bg'
      } else if (location.host.includes('.euro-tax.pl')) {
        return 'bok@euro-tax.pl'
      } else {
        return 'bok@cuf.com.pl'
      }
    },
    companyHelpline () {
      if (this.$i18n.locale === 'ro' || location.host.includes('.ro')) {
        return '031 780 90 94'
      } else if (this.$i18n.locale === 'bg' || location.host.includes('.bg')) {
        return '+359 24 23 59 59'
      } else if (location.host.includes('.euro-tax.pl')) {
        return '71 799 0 600'
      } else {
        return '61 82 88 102'
      }
    },
    companyWWW () {
      if (this.$i18n.locale === 'ro' || location.host.includes('.ro')) {
        return 'euro-tax.ro'
      } else if (this.$i18n.locale === 'bg' || location.host.includes('.bg')) {
        return 'euro-tax.bg'
      } else if (location.host.includes('.euro-tax.pl')) {
        return 'euro-tax.pl'
      } else {
        return 'cuf-podatki.pl'
      }
    }
  }
}
</script>

<style lang="scss">
  .company-address {
    color: #707070
  }

  .purple-color {
    a {
      color: #d2116e;
      text-decoration: none;
    }

    /* unvisited link */
    a:link {
      color: #d2116e;
    }

    /* visited link */
    a:visited {
      color: #d2116e;
    }

    /* mouse over link */
    a:hover {
      color: #d2116e;
    }

    /* selected link */
    a:active {
      color: #d2116e;
    }
      }
</style>
